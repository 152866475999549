.user-settings .app-auth-row {
    margin-bottom: 10px;
}

.user-settings .notice {
    margin-bottom: 30px;
}

.user-settings .logout-button {
    margin-top: 30px;
}

.user-settings .form-group {
    margin-bottom: 40px;
}

.value-row-shift-checkbox {
    top: -12px;
}

.value-row-shift-dropdown {
    top: -8px;
}

.version-commit-id {
    font-size: small;
    margin-left: 5px;
}

.user-settings .form-check {
    margin-top: 0 !important;
}
