
.brush-toolbar, .clear-toolbar {
    font-size: 14px;
    margin-left: 10px;
}
.clear-toolbar {
    display: flex;
    padding: 0;
    flex-direction: column;
}
.line-toolbar {
    font-size: 14px;
    margin-left: 10px;
}

.toolbar .form-check {
    margin-top: 6px !important;
}

.select-a-roi-notification {
    margin-top: 4px;
    margin-bottom: 4px;
}

.brush-width-label, .clear-nth-el-label, .keep-every-nth {
    margin-top: 7px;
    margin-bottom: 7px;
}
.clear-nth-el-label, .keep-every-nth {
    display: flex;    
}
.clear-nth-el-label {
    margin-right: 4em;
}
.keep-every-nth-input-wrapper {
    display: flex;
}
.keep-every-nth-input-container {
    margin: auto 0.25em;
}
.keep-every-nth-input-label {
    margin: auto;
}
.brush-width-value {
    margin: 5px;
    margin-left: 10px; 
    margin-right: 10px;
    width: 50px;
}
.keep-every-nth-number {
    width: 50px;
    max-height: 2em;
}
.clear-apply-btn-wrapper {
    margin: auto 0px;
}
.keep-every-nth-btn-wrapper {
    display: flex;
    margin: auto .5em;
    align-items: center;
}
.keep-every-nth-clear-outside-range-container {
    margin-top: 2px;
    margin-right: 10px;
}
.keep-every-nth-clear-outside-range-container .form-check {
    margin-top: 4px !important;
}
.clear-apply-btn, .keep-every-nth-current-slice-btn {
    vertical-align: baseline;
    margin-left: .5em;    
}
.contouring-undo-buttons {
    margin-left: 7px;
}
.form-check {
    margin-top: 5px;
}
.keep-every-nth-wrapper {
    display: flex;
}
.keep-every-nth-descr {
    margin: auto;
}
.clear-contour-btns-wrapper {
    display: flex;
    justify-content: left;
    margin: 7px 2em 0 0;
}
.clear-contour-btn:first-child {
    margin-right: 2em;
}
.clear-tool-select-roi {
    /* yes, 30.6px -- this is to match the height of the same area when there's buttons in it */
    height: 30.6px;
    margin-top: 7px;
    padding-top: 5px;
    padding-left: 5px;
}
.clear-tool-select-roi-icon {
    margin-left: 4px;
    margin-bottom: 4px;
}
.clear-tool-target-current-roi-icon {
    margin-top: -1px;
}