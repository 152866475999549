.splash {
    position: absolute;
    top: calc(50% - 2em);
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    animation: fadein 1s;

    padding: 80px 200px;
    background-color: rgb(29, 29, 27);
    border-radius: 10px;
}

.splash img {
    margin-bottom: 15px;
}

/* .logobox {
    padding: 50px 100px;
    background-color: rgb(29, 29, 27);
} */

.loading-text-row {
    margin-top: 5px;
    margin-bottom: 15px;
    color: var(--white);
}

.show-id-button {
    float: right;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
