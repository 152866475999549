.structure-set-table {
    margin-top: 7px;
    width: 100%;
}
.structure-set-table tbody {
    font-size: 12px;
}
.structure-set-table .structure-set-selected {
    background-color: var(--secondary);
}
.structure-set-table .approval-indicator {
    margin-right: 5px;
    margin-top: -2px;
}

.structure-set-table th:nth-child(1), .structure-set-table td:nth-child(1) { width: 18px; }
.structure-set-table th:nth-child(2), .structure-set-table td:nth-child(2) {  }

.structure-set-table .structure-set-name {
    padding-left: 3px;
}

.new-structure-set-button td {
    padding-top: 10px;
}

.new-structure-set-button button {
    font-size: 12px;
    padding: 2px 4px;
    width: 100%;
    text-align: left;
}

.structure-set-row {
    cursor: pointer;
    display: flex;
}

.structure-set-row:not(.structure-set-selected):hover {
    background-color: var(--secondary);
}

.structure-set-is-new {
    animation-name: highlight-new-structure-set;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.structure-set-context-menu {
    font-size: 13px;
}

.ss-row{
    cursor: pointer;
    vertical-align: top;
}

@keyframes highlight-new-structure-set {
    0% {
      background-color: #fed962;
    }
    50% {
      background-color: #fbf9c5;
    }
    100% {
      background-color: #fed962;
    }
  }