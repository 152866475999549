.dataset-table  {
    display: block;
    font-size: 12px;
    margin-top: 10px;
}
.dataset-table thead {
    font-size: 13px;
}

.dataset-table td {
    vertical-align: top;
}

.dataset-table th:nth-child(1), .dataset-table td:nth-child(1) { padding-right: 40px; min-width: 180px; }
.dataset-table th:nth-child(2), .dataset-table td:nth-child(2) { padding-right: 40px; }
.dataset-table th:nth-child(3), .dataset-table td:nth-child(3) { padding-right: 20px; }
.dataset-table th:nth-child(4), .dataset-table td:nth-child(4) { padding-right: 10px; }
.dataset-table th:nth-child(5), .dataset-table td:nth-child(5) { padding-right: 20px; }
.dataset-table th:nth-child(6), .dataset-table td:nth-child(6) { padding-right: 20px; }
.dataset-table th:nth-child(7), .dataset-table td:nth-child(7) { padding-right: 20px; }
.dataset-table th:nth-child(8), .dataset-table td:nth-child(8) { padding-right: 20px; min-width: 250px; }

.dataset-table td:nth-child(4) { min-width: 230px; }
.dataset-table td.minimized:nth-child(4) { min-width: 220px; }

.dataset-table .approval-indicator {
    margin-right: 5px;
    margin-top: -1px;
}

.dataset-table .structureset-grading-info {
  margin-left: 6px;
}

.batch-job-model-select {
    margin-left: 10px;
}
    
.batch-job-model-select div {
    /* padding-top: 0 !important; */
    padding-bottom: 0 !important;
    font-size: 14px;
}

.dataset-table .structure-set-list > .structure-set-item {
    display: flex;
}

.dataset-table .structure-set-list > .structure-set-item > .structure-set-workflow-state {
    width: 150px;
    margin-left: 10px;
}

.dataset-table .structure-set-list > .structure-set-item > .structure-set-workflow-state.minimized {
    width: 20px;
}

.dataset-table .structure-set-list > .structure-set-item > .structure-set-info {
    flex-grow: 1;
}

.unsure-rois {
    color: rgb(184, 137, 9);
}

.obsolete-structure-set {
    color: rgb(126, 126, 126);
}

.dataset-table-context-menu {
    font-size: 13px;
}
