.sidebar-button {
    color: var(--white);
    border-radius: 0;
    padding: 0.7em;
    border: none;
    transition: all 150ms ease-out;
    cursor: pointer;
}

.sidebar-button:hover,  .sidebar-button.active {
    color: #F8F8F8;
    background-color: var(--dark-turquoise);
    transition: all 150ms ease-out;

}

.sidebar-button:focus {
    outline: none;
}

.home-hover:hover {
    background-color: var(--tertiary);
}

