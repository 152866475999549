.supporting-scans-header {
    margin-right: 20px;
}

.supporting-scan-link {
    margin-right: 10px;
}

.supporting-scan-icon {
    margin-right: 5px;
}

.supporting-scan-tooltip.tooltip-inner {
    text-align: left !important;
    max-width: 600px !important;
}

.supporting-scan-spinner {
    display: flex;
}

.supporting-scan-spinner .spinner {
    margin-right: 15px;
}
