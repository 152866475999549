.pagination-row:not(:first-of-type) {
    margin-top: 20px;
}

.tasks-page {
    transition: all 0.3s ease;
}

.tasks-layout {
    display: table-inline;
}

.float-right {
    float: right;
}

.no-tasks {
    text-align: center;
    margin-top: 20px;
}

.ml-0 {
    margin-left: 0;
}

.w-25em {
    width: 24em !important;
}

.w-10em {
    width: 10em !important;
}

.filters {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 3fr 1fr;
    grid-gap: 10px;
    margin-bottom: 8px;
}
