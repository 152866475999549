.batch-job-item {
    margin: 5px 0;
    border: 4px solid rgb(219, 219, 219);
    border-radius: 6px;
    padding: 5px 10px;
}

.batch-job-item .title-row {
    font-weight: 600;
}

.batch-job-item .title-row .source-file-share {
    color: rgb(23, 72, 102);

}

.batch-job-item .title, 
.batch-job-item .user, 
/* .batch-job-item .date, */
.batch-job-item .storage-account,
.batch-job-item .file-share {
    font-style: italic;
    color: rgb(54, 54, 54);
}

.batch-job-item .export-folder {
    border: 1px solid rgb(172, 172, 172);
    border-radius: 2px;
    padding: 3px 8px;
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(83, 82, 82);
    background-color: rgb(245, 243, 243);
}

.batch-job-item .title {
    color: rgb(83, 83, 83);
}

.batch-job-item .description {
    color: rgb(83, 83, 83);
    font-size: 14px;
}

.batch-job-item .progress-bar-container {
    position: relative;
    bottom: -6px;
}

.batch-job-item .progress-numbers {
    font-size: 12px;
    position: relative;
    bottom: -3px;
    color: rgb(54, 54, 54);
}

.batch-job-item .storage-info {
    margin-top: 1px;
    font-size: 13px;
}


.batch-job-item .item-status {
    /* float: right; */
    position: absolute;
    right: 50px;
    z-index: 2;
}

.batch-job-item .item-status > div {
    margin-top: 10px;
}

.batch-job-item .item-status > div:first-child {
    margin-top: 5px;
}

.batch-job-item .item-status > .in-progress {
    color: rgb(25, 121, 150);
}

.batch-job-item .item-status > .finished {
    color: rgb(17, 156, 63);
}

.batch-job-item .item-status > .errors {
    color: rgb(204, 71, 71);
    cursor: pointer;
}

.batch-job-item .item-status > .errors:hover {
    color: rgb(156, 14, 14);
}