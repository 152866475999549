.task-details-tab {
  margin-top: 7px;
  margin-bottom: 5px;
  display: flex;
  padding: 0;
  justify-content: space-between;
}

.differences-toolbar {
  margin-top: 10px;
  margin-left: 5px;
}

.expand-desc-button {
    margin-left: 10px;
    border: 1px solid var(--white) !important;
}

.row-span {
display: flex;
align-items: center;
justify-content: center;
}

.float-right {
  margin-left: 20px;
  margin-top: 5px;
}

.pref-item {
  align-self: center;
}

.tool-buttons {
  height: 100%;
  display: grid;
}

table.modalTable {
  background-color: var(--primary);
  border-collapse: collapse;
  border-width: 2px;
  border-color: var(--white);
  border-style: solid;
  color: var(--white);
}

table.modalTable td,
table.modalTable th {
  border-width: 2px;
  border-color: var(--white);
  border-style: solid;
  padding: 3px;
}

.right-margin {
  margin-right: 10px;
}

.modalTable {
  display: table;
  /* this will prevent react-bootstrap overlay tooltips from flickering at bottom rows of the table */
  margin-bottom: 40px;
}

.inline-spinner {
  margin-left: 10px;
}

.disabled-btn {
  background-color: gray !important;
    opacity: 0.4;
}

/* show a horizontal scrollbar if metrics table is too wide */
.metrics-table {
  overflow-x: auto;
}

.metrics-table .info-cell {
  min-width: 125px;
}

.metrics-table .metric-cell {
  min-width: 90px;
}


.selectors-container {
    align-items: baseline;
    justify-content: flex-end;
    margin-right: auto;
}
.formatted-error-message > div {
  margin-bottom: 20px;
}
