.metrics-selector {
    text-align: right;
    padding: 5px 0 5px 5px;
}

.metrics-selector.embedded {
    padding: 0;
}

.metrics-headers th {
    vertical-align: top !important;
}

.metrics-selector .dropdown-item {
    cursor: pointer;
}

.metrics-selector .dropdown-item:focus {
    background-color: #6d6d6d !important;
}

.metrics-selector-item .form-check {
    margin-top: 0 !important;
}

.metrics-selector-item .form-check * {
    cursor: pointer;
}

.metrics-selector-text {
    margin-right: 12px;
}

.metrics-selector-badge {
    margin-right: 5px;
}

.metrics-selector-hide-arrow.dropdown-toggle::after {
    display: none !important;
}