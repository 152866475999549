.info-text {
    font-weight: bold;
    padding-bottom: 1px;
    border-bottom: 1px dashed #397abb;
    color: #397abb;
}

.info-text-icon {
    color: #397abb;
}
