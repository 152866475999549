.preferences {
    margin: 5px;
    display: inline-grid;
    grid-auto-flow: column;
    width: max-content;
}

.pref-item {
    margin-right: 5px;
}

.pref-label {
    width: 180px;
    white-space: nowrap;
}
.pref-value {
    border: 1.5px solid var(--white);
    border-radius: 5px;
    margin: 2px;
    padding-left: 5px;
    width: 60px;
}

.form-check {
    margin-top: -5px;
}