:root {
    --primary: #2c2c2c;
    --white: #f3f3f3;
    --secondary: #383838;
    --tertiary: #444444;
    --table-odd-row: #272727;
    --lock-red: #dc3545;
    --dark-blue: #006a6f;
    --dark-turquoise: #016f83;
    --light-turquoise: #02b1c0;
    --orange: #f49800;
    --light-green: #35dc71;
    --white-dim: #9e9d9d;

  /* purple theme
    --primary: #282253; 
    --white: #f7f7f7;
    --secondary: #38306c;
    --tertiary: #393461; */

  /* blue theme
    --primary: #030018;
    --white: #f3f3f3;
    --secondary: #0b081e;
    --tertiary: #0d092b; */

  /* gray theme
    --primary: #2c2c2c;
    --white: #f3f3f3;
    --secondary: #383838;
    --tertiary: #444444; */
  
  --card-color: rgb(20, 20, 20);

  overflow-x: hidden;
  transition: 0.3s ease-in;
}


/* Poppins font with font weight 100 (Thin) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
}

/* Poppins font with font weight 200 (Extra Light) */
@font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

/* Poppins font with font weight 300 (Light) */
@font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

/* Poppins font with font weight 400 (Normal) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Poppins font with font weight 500 (Medium) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* Poppins font with font weight 600 (Semi Bold) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-SemiBold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

/* Poppins font with font weight 700 (Bold) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* Poppins font with font weight 800 (Extra Bold) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-ExtraBold.ttf") format('truetype');
    font-weight: 800;
    font-style: normal;
}

/* Poppins font with font weight 900 (Black) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
}


body {
    font-family: 'Poppins', sans-serif;
}

.cards-container {
  display: flex;
  gap: 10px;
}

#cards:hover > .card-btn::after {
  opacity: 1;
}

.card-btn {
  border-radius: 10px;
  display: flex;
  grid-column: 3/6;
  grid-row: 1;
  flex-direction: column;
  position: relative;
}

.card-btn:hover::before {
  opacity: 2;
}

.card-btn::before,
.card-btn::after {
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  transition: opacity 200ms;
  width: 100%;
}

.card-btn::before {
  background: radial-gradient(
    700px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.1),
    transparent 60%
  );
  z-index: 3;
}

.card-btn::after {
  background: radial-gradient(
    600px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.9),
    transparent 80%
  );
  z-index: 1;
}

.card-btn > .card-content {
  background-color: var(--primary);
  border-radius: inherit;
  display: grid;
  flex-direction: column;
  flex-grow: 1;
  inset: 1px;
  padding: 10px;
  position: absolute;
  z-index: 2;
}

.form-control, 
.select-input > div  {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  border: 2px solid #e9e9e9 !important;
  border-radius: 5px;
}

.dropdown-menu, .btn, 
.select-input > div > div > div:hover{
  background-color: var(--secondary) !important;
}

.btn-danger {
  background-color: var(--red) !important; 
}

body,
.modal-content,
button.close,
.sketch-picker,
.roi-context-menu,
.btn-light.disabled,
.roi-select div,
.btn-light.active,
.btn-light:active,
.dropdown-item:hover, 
.table .table,
.react-contexify,
table, 
input,
.form-control:focus, 
.select-input > div,
.sort-select,
.select-input > div > div > div {
  background-color: var(--primary) !important;
}

/* white font color */
body,
.dropdown-menu > a,
.css-1uccc91-singleValue,
.roi-context-menu > a,
.modal-content,
button.close,
.btn-light.disabled,
.btn-light:disabled,
.roi-select,
.sketch-picker,
.btn,
.btn:hover,
.dropdown-item:hover,
.table,
.table .table,
.react-contexify__item__content,
.react-contexify,
.dropdown-item,
input,
table,
.mv-landing-page-card,
label.btn.btn-default.btn-sm.btn.active.btn-light,
.select-input > div > div > div:hover {
  color: var(--white) !important;
}

.selected-grade, .btn-outline-info {
  background-color: var(--dark-turquoise) !important;
}

.selected-grade {
  box-shadow: 0 0 0 2px var(--dark-turquoise) !important;
}

.modal-header {
  border-bottom: 0;
}
.modal-footer {
  border-top: 0;
}

.dataset-table .odd-row,
.task-group-table .odd-row {
  background-color: var(--secondary);
}

.task-group-table tbody tr,
.dataset-table tbody tr {
  background-color: var(--tertiary);
}

.dataset-table tbody tr:hover,
.table-hover tbody tr:hover {
  background-color: var(--table-odd-row);
  color: var(--white);
}

.notification-alert > button {
  background-color: transparent !important;
  color: var(--tertiary) !important;
}

label.btn.btn-default.btn-sm.btn.active.btn-light,
.btn-light:disabled,
.btn:hover,
.select-input:disabled {
  background-color: var(--tertiary) !important;
}

.rois-div {
  display: flex;
  flex-flow: wrap;
}

.roi-p {
  margin-top: 1px;
  margin-bottom: 2px;
  margin-right: 5px;
  margin-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.roi-div {
  display: inline-flex;
  align-items: center;
  margin-top: 3px;
  margin-right: 5px;
  border: 1px solid white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.roi-div.resident-already-included {
  background-color: #a78000;
}

.roi-div .task-prev-assigned-notification {
  margin-left: 10px;
}

.times {
  margin-left: 10px;
  cursor: pointer;
}


.sketch-picker {
  width: 96%;
}

button.close:hover {
  color: var(--white) !important;
}
.modal-content {
  border: 1px solid #eeeeee;
  border-radius: 5px;
}

.btn {
  border: 0;
  margin: 1px;
}

tr & .btn::active {
  background: #4b525a;
}
tr & .btn::disabled {
  color: #4b525a;
  background-color: #16191c;
}

.toggle-btn {
  cursor: pointer;
}

.toggle-btn.active {
    border-bottom: 1px solid;
}

.visible {
  display: inline;
}

.invisible {
  display: none;
}

.clickable-glyph {
  cursor: pointer;
  color: rgb(66, 66, 66);
}

.clickable-glyph:hover {
  color: rgb(12, 12, 12);
}

/* bootstrap overrides */

.dropdown-menu {
  max-height: 800px !important;
  overflow-y: auto !important;
}

/* this fixes an issue with the page content jumping around when a modal dialog is opened/closed, part 1 */
body:not(.modal-open){
  padding-right: 0px !important;
}

/* this fixes an issue with the page content jumping around when a modal dialog is opened/closed, part 2 */
.modal-open {
  overflow: hidden;
  padding-right: 0 !important;
}


.view-button {
  background-color: var(--dark-blue) !important;
}
.view-button:hover {
  background-color: var(--dark-turquoise) !important;
}

.lock-button {
  background-color: var(--lock-red) !important;
}
.lock-button:hover {
  background-color: var(--lock-red) !important;
  opacity: 0.75;
}

.primary-button {
  background-color: var(--primary) !important;
}
.primary-button:hover {
  background-color: var(--tertiary) !important;
}

.grade-ready {
  background-color: var(--dark-blue) !important;
  color: var(--white) !important;
}

.orange {
  background-color: #b77200 !important;
  color: var(--white) !important;
}

.orange:hover {
  background-color: var(--orange) !important;
  color: var(--white) !important;
}

.turquoise {
  background-color: var(--dark-turquoise) !important;
  color: var(--white) !important;
  font-weight: 500;
}

.turquoise:hover {
  background-color: var(--light-turquoise) !important;
}

.turquoise-l {
  background-color: var(--light-turquoise) !important;
  color: var(--white) !important;
  font-weight: 500;
  
}

.turquoise-l:hover {
  background-color: var(--dark-turquoise) !important;
}
