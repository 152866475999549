.landing-container {
    width: 100%;
}

.landing-global-toolbar-container {
    background: var(--primary);
    height: fit-content;
    width: 100%;
    height: 32px;
    z-index: 20;
    position: relative;
}

.landing-panels {
    display: flex;
    justify-content: space-evenly;
}

.landing-panels .home-panel {
    max-width: 1000px !important;
}

.landing-panels .home-logo {
    text-align: center;
}
