.sidebar-menu {
    position: fixed !important;
    top: 0;
    left: 0;
    display: inline-grid !important;
    margin-right: 5px;
    z-index: 1;
    transition: all 150ms ease-out;
    opacity: 1;
}

.menu {
    height: 100%;
    background-color: #F8F8F8;
}

.hide {
    transform: translateX(-70px);
    transition: all 200ms ease-out;
}