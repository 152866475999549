.calculation-time-note {
    margin-bottom: 20px;
}

.roi-alias-table .test-roi-name {
    min-width: 200px;
    vertical-align: middle;
}

.roi-alias-table .test-roi-alias {
    min-width: 300px;
}

.alias-map-editor-button {
    position: absolute;
    right: 14px;
    margin-top: -40px;
}

.save-alias-check {
    text-align: center;
    vertical-align: middle !important;
}

.calculate-metrics-badge {
    margin-left: 2px;
}

.roi-mapping-save-alias-column {
    width: 130px;
}

.roi-alias-map-save-toggle-all {
    display: inline;
    margin-left: 5px !important;
    margin-right: 10px !important;
    top: inherit !important;
}

.calculation-modal-body .top-actions-bar {
    margin-bottom: 15px;
}

.calculation-modal-body .top-actions-bar button {
    margin-right: 5px;
}

.metrics-test-header {
    padding-left: 5px !important;
}

.test-roi-name {
    padding-left: 5px !important;
}

.metrics-reference-header {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
}

.metrics-clear-all-mappings-button {
    color: var(--white-dim) !important;
    text-decoration: none !important;
    margin-right: 5px;
}

.metrics-clear-all-mappings-button:hover,
.metrics-clear-all-mappings-button:active {
    color: var(--white) !important;
}

.metrics-clear-all-mappings-button > .clear-icon {
    margin-right: 3px;
    position: relative;
    top: -1px;
}
