.crash-page > div {
    margin-bottom: 10px;
}

.error-message-container {
    white-space: pre-wrap;
    font-family: 'Courier New';
}

.crash-heading {
    font-size: larger;
    font-weight: bolder;
}