.batch-job-toolbar {
    margin-bottom: 20px;
}

.tri-state-select-all {
    position: relative;
    top: 5px;
}

.tri-state-select-all .tri-state-checkbox {
    top: 2px;
}

.batch-job-toolbar > .toolbar > *:not(:first-child) {
    margin-left: 15px;
}