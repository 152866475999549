.local-page-not-for-clinical {
    color: red;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;
}

.local-page-container {
    width: auto;
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 70px;
}

.grid-center{
    display: grid;
    place-items: center;
}

.image-form {
    margin-bottom: 0px;
    display: flex;
    align-items: flex-end;
    flex-flow: wrap;
}

.rtstruct-form {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
}

.version-number {
    margin-top: 30px;
    font-size: 12px;
    position: fixed;
    bottom: 10px;
}

.global-toolbar-for-local {
    margin-top: -18px;
    margin-right: 10px;
}

.file-load-errors-alert { 
    height: min-content;
    max-height: 90%;
    position: absolute;
    right: 30px;
    bottom: 15px;
    overflow-y: auto;
}

.file-load-errors {
    display: flex;
}

.file-load-errors .filename {
    width: 300px;
    line-break: anywhere;
}

.hide-file-form {
    display: none;
}

/*.popup {*/
/*    position: fixed;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    margin: auto;*/
/*    background-color: rgba(0,0,0, 0.5);*/
/*}*/
.popup {
    position: absolute;
    left: 1%;
    right: 25%;
    top: 1%;
    /*bottom: 25%;*/
    margin: auto;
    background: var(--primary);
    opacity: 0.25;
}


.popup:hover {
    position: absolute;
    left: 1%;
    right: 25%;
    top: 1%;
    /*bottom: 25%;*/
    margin: auto;
    background: var(--primary);
    opacity: 0.9;
}

.center {
    margin-left: auto;
    margin-right: auto;
}


.flex {
    display: flex;
}

.bottom {
        margin-bottom: auto;
}


.reverse-flow {
    display: flex;
    justify-content: left;
}

.even {
    justify-content: space-evenly;
}
.card {
    background-color: var(--primary);
    border: 2px solid var(--secondary) !important;
    border-radius: 10px
}

.info1 {
    width: 65%;
    font-weight: 400;
    margin-top: 1.1rem;
    align-items: center;
}

.card-btn {
    background-color: var(--primary);
    color: var(--white);
    border: 2px solid var(--secondary);
    width: 28rem;
    height: 35rem;
    margin: 0.9rem;
    transition: all 0.2s ease-in-out;
    justify-content: center;
}

.col-card-btn {
    height: 3.5rem;
    width: 20rem;
    background-color: var(--dark-turquoise);
    border-radius: 50px !important;
}

.row-card-btn {
    height: 30rem;
}

.card-shadow {
    padding: 12px;
}

.home-panel {
    background-color: var(--primary);
    border: 1px solid #000;
    border-radius: 10px;
    max-width: 700px;
    padding: 60px 40px;
}

.home-logo {
    margin-bottom: 60px;
}

.home-panel .subtitle {
    font-size: 12px;
}

.scan-progress-bar {
    margin-top: 10px;
    max-width: 65%;
}

.home-page>.panels {
    display: flex;
    justify-content: space-between;
    z-index: 2;
}

.home-page .image-panel-container {
    align-self: center;
    flex-grow: 1;
}

.image-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.home-carousel {
    width: 800px;
}

.home-carousel img {
    width: 800px;
}

.caption-box {
    height: 150px;
    text-align: center;
}

.caption-box .caption {
    color: var(--primary);
    padding: 20px;
    background-color: var(--white);
    width: 800px;
    min-height: 130px;
}

.local-background {
    background: url("/img/gradient.jpg") no-repeat center center fixed;
    background-color: rgb(207, 207, 207);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.local-foreground {
    z-index: 2;
    position: relative;
}

.icon-btn {
    justify-content: start;
    flex-flow: wrap;
    align-content: space-evenly;
    justify-content: center;
}

.icon-btn > h5 {
    margin: 0;
}

.card-delineation {
    min-width: 20rem;
    max-width: 40rem;
}

.dark-file-input-container {
  display: inline-block;
  position: relative;
  margin-left: 10px;
}

.dark-file-input-label {
  background-color: #333; /* Dark background color */
  color: white; /* White text color */
  padding: 8px 16px; /* Adjust padding as needed */
  border-radius: 4px; /* White corners */
  cursor: pointer;
}

/* Hide the input */
.dark-file-input {
  display: none;
}

.example-img {
    width: 30rem;
    margin-top: 2rem;
    padding: 0.25rem;
    border-radius: 10px;
}

.logo-img {
    width: 15rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.turquoise {
    margin-bottom: 1rem;
}

.right {
    display: grid;
    align-content: center;
}

.description-text {
    width: 60%;
}

.footer-apps {
    background-color: var(--dark-turquoise);
    padding: 4px 0; /* Add padding to improve spacing */
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 999; /* Add a z-index to ensure it's on top of other content */
    margin-top: 1rem;
}

.left, .right {
    height: 100%;
}

/* For screens smaller than or equal to 768px (small and medium) */

@media (max-width: 1240px) {
  .reverse-flow {
    flex-direction: column-reverse;
  }
  .card-delineation {
    width: auto;
  }
  .description-text {
    width: 80%
}
  .example-img {
    width: 450px;
}
}

@media (max-width: 920px) {
  .reverse-flow {
    flex-direction: column-reverse;
  }
  .card-delineation {
    width: auto;
  }
  .example-img {
    display: none;
}
.description-text {
    width: 100%
}
}

@media (max-width: 440px) {
  .card-delineation {
    width: 10rem;
  }

.info1 {
    width: 80%
}
}

#scan-selector {
    margin-right: 20px;
    margin-top: -10px;
    margin-bottom: 20px;
    min-width: 250px;
}
