.heading {
    text-align: center;
    margin-bottom: 1em;
}

.heading-img {
    text-align: center;
    width: 300px;
    margin-bottom: 1em;
}

.align-right {
    text-align: left;
    margin-bottom: 0.5em;
}