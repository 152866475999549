.mb-small {
    margin-bottom: 0.4rem !important;
}
input {
    margin-bottom: 0 !important;
}

select {
    margin-bottom: 0 !important;
}

textarea.form-control {
    height: 100px;
}

.dialog-spinner {
    width: 100%;
    text-align: center;
    margin: 40px 0 30px 0;
}
