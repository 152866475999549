
.update-button {
    margin-top: 15px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.update-col {
    text-align: center;
}

.m-right {
    margin-right: 10px;
}

textarea.form-control {
    height: 100px;
}