.window-level-edits {
    width: 900px;
    margin-left: 0px;
    padding: 7.5px;
}

.preset-dropdown {
    margin-left: 15px;
    margin-right: 25px;
}

.wwwc-item {
    margin-right: 5px;
}
.wwwc-label {
    margin-top: 5px;
    margin-right: 5px;
}
.wwwc-value {
    width: 60px;
    margin-right: 2px;
    border: 1.5px solid var(--white);
    border-radius: 5px;
    margin: 2px;
    padding-left: 5px;
}

.preset-name {
    margin-top: 3px;
    margin-right: 10px;
    width: 220px;
    border: 1.5px solid var(--white);
    border-radius: 5px;
    margin: 2px;
    padding-left: 5px;
}

.window-level-edits .btn {
    margin-top: 3px;
}