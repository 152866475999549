.spinner-container {
  position: absolute;
  margin-top: -2px;
}

.spinner-resize {
  width: 11px;
  height: 11px;
  border-width: 3px;
}

.uploading-progress-bar .progress-bar {
  color: var(--white);
  background-color: #68b1ff;
  text-align: left;
  padding-left: 3px;
}

.contouring-failed {
  background-color: #ff9696;
}

.contouring-failed .error-message {
  display: flex;
}

.contouring-failed .error-message-buttons {
  text-align: right;
  flex-grow: 1;
  margin-right: 5px;
}

.contouring-failed .error-message-buttons span {
  margin-left: 5px;
}