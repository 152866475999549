.confirm-grading-save-dialog {
    font-size: 15px;
}

.confirm-grading-save-dialog .duplicates-matching-current-roi > div {
    margin-bottom: 20px;
}

.confirm-grading-save-dialog .other-duplicates > div {
    margin-bottom: 20px;
}

.confirm-grading-save-dialog .grading-meaning {
    font-family: monospace;
    background-color: rgb(231, 231, 231);
    font-size: 14px;
    margin: 0 4px;
    padding: 0 2px;
}

.confirm-grading-save-dialog .duplicates-list {
    font-size: 14px;
}

.confirm-grading-save-dialog .duplicates-list .grading-meaning {
    font-size: 13px;
}

.confirm-grading-save-dialog .duplicates-list-item {
    margin-bottom: 5px;
}

.confirm-grading-save-dialog .duplicate-regrade-warning {
    font-size: 13px;
    background-color: rgb(243, 220, 142);
}

.confirm-grading-save-dialog .duplicate-regrade-warning .grading-meaning {
    font-size: 12px;
}

.confirm-grading-save-dialog .duplicate-structure-set-list {
    font-style: italic;
}