.roi-table  {
    display: block;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.roi-table thead {
    font-size: 13px;
}

.roi-table td {
    vertical-align: middle;
}

.similarity-metrics-selector-floater {
    float: right;
    height: 0;
    top: -55px;
    position: relative;
}

.acceptance-criteria-table {
    margin-top: 0 !important;
}

.ac-cell {
    min-width: 100px;
}
