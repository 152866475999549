.tasks-group-table, .tasks-table {
    font-size: 12px;
    margin-bottom: 20px;
    color: var(--white);
    background-color: var(--secondary);
}

.tasks-group-table thead, .tasks-table thead {
    font-size: 13px;
    word-break: normal;
}

.tasks-table td, .tasks-group-table td {
    vertical-align: middle;
    word-break: break-word;
}

.tasks-table th:nth-child(1), .tasks-table td:nth-child(1) { padding-right: 10px; width: 120px;}
.tasks-table th:nth-child(2), .tasks-table td:nth-child(2) { padding-right: 10px; width: 60px}
.tasks-table th:nth-child(3), .tasks-table td:nth-child(3) { padding-right: 10px;  width: 110px;  }
.tasks-table th:nth-child(4), .tasks-table td:nth-child(4) { padding-right: 10px;  width: 90px;  }
.tasks-table th:nth-child(5), .tasks-table td:nth-child(5) { padding-right: 10px; width: 140px;}
.tasks-table th:nth-child(6), .tasks-table td:nth-child(6) { padding-right: 10px; width: 40px; }
.tasks-table th:nth-child(7), .tasks-table td:nth-child(7) { padding-right: 10px;  width: 150px; word-break: break-word; }
.tasks-table th:nth-child(8), .tasks-table td:nth-child(8) { padding-right: 10px;  width: 150px; word-break: break-word; }
.tasks-table th:nth-child(9), .tasks-table td:nth-child(9) { padding-right: 10px;  width: 150px }
.tasks-table th:nth-child(10), .tasks-table td:nth-child(10) { padding-right: 10px;  width: 120px }
.tasks-table th:nth-child(11), .tasks-table td:nth-child(11) { padding-right: 10px;  width: 120px }
.tasks-table th:nth-child(12), .tasks-table td:nth-child(12) { padding-right: 10px;  width: 120px }


.task-group-table {
    background-color: var(--tertiary);
}
.task-group-table .task-row.is-expanded {
    /* border-left: var(--white); */
    background-color: var(--table-odd-row);
    box-shadow: inset 1px 0 0 0 var(--white);
}

.task-group-table .expanded-task-group {
    box-shadow: 1px 0 0 0 var(--white) inset;
    border-bottom: 1px var(--white) solid;
    background-color: var(--table-odd-row);
}

.tasks-table .approval-indicator {
    margin-right: 5px;
    margin-top: -1px;
}

.tasks-table .structureset-grading-info {
  margin-left: 6px;
}


.tasks-table .structure-set-list > .structure-set-item {
    display: flex;
}

.tasks-table .structure-set-list > .structure-set-item > .structure-set-workflow-state {
    
    margin-left: 10px;
}

.tasks-table .structure-set-list > .structure-set-item > .structure-set-workflow-state.minimized {
    width: 20px;
}

.tasks-table .structure-set-list > .structure-set-item > .structure-set-info {
    flex-grow: 1;
}

.unsure-rois {
    color: rgb(184, 137, 9);
}

.obsolete-structure-set {
    color: rgb(126, 126, 126);
}

.tasks-table-context-menu {
    font-size: 13px;
}
