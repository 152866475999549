.show-error-message-button {
    margin-top: 5px;
    margin-bottom: 10px;
}

.pagination-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .pagination {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 0 !important;

  }
  
  .pagination li {
    margin: 0;
    border: 0 !important;
    padding: 0;
  }
  
  .pagination a {
    display: block;
    color: var(--white) !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    background-color: transparent;
    border: 1px solid var(--tertiary) !important;
    border-radius: 4px;
  }
  
  .pagination a:hover {
    background-color: var(--tertiary) !important;
    color: var(--light-turquoise) !important;
  }
  
  .pagination .active a {
    color: #fff;
    background-color:  var(--secondary) !important;
    border-color:  var(--tertiary) !important;
    
  }
  
  .pagination .disabled a {
    color: #6c757d;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
  }
  
  .pagination .previous a:before {
    content: "\\2039";
  }
  
  .pagination .next a:after {
    content: "\\203a";
  }
  
  .pagination .previous.disabled a:before,
  .pagination .next.disabled a:after {
    opacity: .5;
  }

  
.guide-logo {
  margin-left: 4rem;
  margin-top: 1rem;
  width: 150px;
}